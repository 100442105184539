.pika-lendar {
  width: 300px;
  margin: 15px 10px;
}
.pika-single {
  border-color: #fabe79;
  box-shadow: 3px 4px 0 0 #fabe79;
  font-family: 'HailsR';
}

.pika-single.is-bound {
  box-shadow: 3px 4px 0 0 #fabe79;
}

.pika-next, .pika-prev {
  color: #212840;
  opacity: 1;
}

.pika-label {
  font-size: 21px;
  font-weight: 500;
  color: #212840;
  font-family: 'HalisR-Bold';
}

.pika-table th {
  font-size: 14px;
  font-weight: 500;
  color: #212840;
}

.pika-table td {
  padding: 0;
}

.pika-table abbr {
  text-decoration: none;
  font-family: 'HalisR-Bold';
}

.pika-button {
  background: #fff;
  padding: 10px;
}

.pika-button:hover {
  background: #ffda8e;
  border-radius: 0;
  color: #212840;
}

.pika-button.pika-day {
  text-align: center;
}

.is-today > .pika-button {
  color: #212840;
}

.is-selected > .pika-button {
  background: #ffda8e;
  border-radius: 0;
  font-weight: normal;
  box-shadow: none;
  color: #212840;
}

.is-disabled > .pika-button {
  color: #b1bfe3;
  background: #fff;
}
